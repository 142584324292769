// Copyright © 2022 Move Closer

import Vue from 'vue'

import { Context } from '@contract/context'
import { UVuePlugin } from '@contract/uvue-plugin'
import { validateContext } from '@support/validate-context'

import { MobileESP } from './user-agent.contracts'
import { mobileESPFactory } from './user-agent.helpers'

/**
 * Plugin responsible for intercepting the API call, extracting the user agent info and populating the Vuex store.
 * Thanks to this plugin you can use checks like `isMobile` or `isDesktop` in the `<script nonce=fvwe3p9IrX2HZiC2xDswc644oWRVgH52>` or `<template>` section of
 * your Vue SFC.
 *
 * @see State.device
 *
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
const UserAgentPlugin: UVuePlugin = {
  async created (context: Context) {
    validateContext(context)

    if (context.isClient) {
      return
    }

    const req = context.ssr?.req
    if (typeof req === 'undefined') {
      throw new Error('UserAgentPlugin.created(): FATAL! [context.ssr?.req] is [undefined]!')
    }

    const userAgent: string | undefined = req.headers['user-agent']
    if (typeof userAgent !== 'string') {
      throw new Error('UserAgentPlugin.created(): FATAL! [userAgent] is not a type of [string]!')
    }

    const mobileESP: MobileESP = mobileESPFactory()
    mobileESP.InitDeviceScan(userAgent)

    const isPhone: boolean = mobileESP.isMobilePhone
    const isTablet: boolean = mobileESP.isTierTablet
    const isDesktop: boolean = !isPhone && !isTablet
    const isMobile: boolean = isPhone || isTablet

    // We can safely use the `as` keyword here, as all the required checks have been made inside the above
    // `validateContext()` function.
    await (context.app as Vue).$store.dispatch('setDevice',
      { isDesktop, isMobile, isPhone, isTablet, userAgent }
    )
  }
}

export default UserAgentPlugin
